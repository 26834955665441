import '../styles/main.scss';
import React, { useEffect } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { SessionProvider, useSession } from 'next-auth/react';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { Provider } from 'react-redux';
import { store, persistor } from '../store/store';
import { PersistGate as PersistGateClient } from 'redux-persist/integration/react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import routes from 'lib/routes';
import Script from 'next/script';
import { useRouter } from 'next/router';
import * as gtag from '../lib/gtag';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import * as fbq from '../lib/fpixel';
class PersistGateServer extends React.Component<{
  children: ReactNode;
}> {
  render() {
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  auth?: any | undefined;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
export default function MyApp({
  Component,
  pageProps
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);
  const runtime = process.env.RUNTIME;
  let PersistGate = (PersistGateServer as any);
  if (runtime === 'browser') {
    PersistGate = PersistGateClient;
  }

  /* Google analytics related */
  const router = useRouter();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  /* Pixel Meta related */
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();
    const handleRouteChange = () => {
      fbq.pageview();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return <>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script id='fb-pixel' strategy='afterInteractive' dangerouslySetInnerHTML={{
      __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `
    }} />
      <SessionProvider>
        <Provider store={store}>
          {process.env.NODE_ENV === 'production' && <>
              <Script id="googleclarity" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", 'gkcldbhva5');`
          }} />
              <Script src={'https://wow.zamimg.com/js/tooltips.js'} />
              {/* Global Site Tag (gtag.js) - Google Analytics */}
              <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />
              <Script id="googleAnalytics" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                    })
                  `
          }} />
            </>}
          {/* eslint-disable-next-line */}
          <PersistGate loading={null} persistor={persistor}>
            <ReCaptchaProviderCheck>
              <SSRProvider>
                {Component.auth ? <Auth auth={Component.auth}>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    {getLayout(<Component {...pageProps} />)}
                  </Auth> : <>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    {getLayout(<Component {...pageProps} />)}
                    </>}
                </SSRProvider>
            </ReCaptchaProviderCheck>
          </PersistGate>
        </Provider>
      </SessionProvider>
    </>;
}
interface IAuthProps {
  children: React.ReactNode;
  auth: {
    role?: string;
    unauthorized?: string;
  };
}
const ReCaptchaProviderCheck = ({
  children
}: {
  children: React.ReactNode;
}) => {
  // Only load ReCaptchaProvider if we are in production
  if (process.env.NODE_ENV === 'production') {
    // Get the current route
    const router = useRouter();

    // Only load ReCaptchaProvider if we are on the register path
    if (router.pathname === routes.Auth.Register) {
      return <ReCaptchaProvider useEnterprise>
        {children}
      </ReCaptchaProvider>;
    }
  }
  return <>
      {children}
    </>;
};
const Auth = ({
  children,
  auth
}: IAuthProps) => {
  const {
    status,
    data
  } = useSession({
    required: true,
    onUnauthenticated() {
      if (typeof auth === 'boolean') {
        window.location.href = '/auth/signin';
      } else if (auth.unauthorized) {
        window.location.href = auth.unauthorized ?? '/auth/signin';
      } else {
        window.location.href = '/auth/signin';
      }
    }
  });
  if (status === 'loading') {
    return <>
        <div>
          <h1>Loading...</h1>
        </div>
      </>;
  }
  if (auth.role) {
    const {
      user
    } = data;
    if (user!.role !== auth.role) {
      window.location.href = routes.Portal.Profile;
    }
  }
  return <>
      {children}
    </>;
};