import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MiscState {
  openSideBar: boolean
}

const initialState: MiscState = {
  openSideBar: false
}

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setOpenSideBar: (state, action: PayloadAction<boolean>) => {
      state.openSideBar = action.payload
    }
  }
})

export const { setOpenSideBar } = miscSlice.actions

export default miscSlice.reducer
