export default {
  Auth: {
    Login: '/auth/signin',
    Register: '/auth/register',
    ForgotPassword: '/auth/forgot-password'
  },
  ServerNews: {
    View: '/server-news/:slug'
  },
  Server: {
    View: '/wow/private-servers/:expansion-private-servers/:slug'
  },
  Expansions: {
    Vanilla: '/wow/private-servers/vanilla-private-servers',
    TBC: '/wow/private-servers/tbc-private-servers',
    WotLK: '/wow/private-servers/wotlk-private-servers',
    Cataclysm: '/wow/private-servers/cataclysm-private-servers',
    MOP: '/wow/private-servers/mop-private-servers',
    WOD: '/wow/private-servers/wod-private-servers',
    Legion: '/wow/private-servers/legion-private-servers',
    BFA: '/wow/private-servers/bfa-private-servers',
    Shadowlands: '/wow/private-servers/shadowlands-private-servers',
    Dragonflight: '/wow/private-servers/dragonflight-private-servers'
  },
  AddonExpansions: {
    Vanilla: '/wow/addons/vanilla-addons',
    TBC: '/wow/addons/tbc-addons',
    WotLK: '/wow/addons/wotlk-addons',
    Cataclysm: '/wow/addons/cataclysm-addons',
    MOP: '/wow/addons/mop-addons',
    WOD: '/wow/addons/wod-addons',
    Legion: '/wow/addons/legion-addons',
    BFA: '/wow/addons/bfa-addons',
    Shadowlands: '/wow/addons/shadowlands-addons',
    Dragonflight: '/wow/addons/dragonflight-addons'
  },
  Portal: {
    Owner: {
      Servers: '/portal/owner/servers',
      Server: {
        Logo: '/portal/owner/servers/:id/logo',
        Realm: '/portal/owner/servers/:id/realm',
        Details: '/portal/owner/servers/:id/details',
        Success: '/portal/owner/servers/:id/success'
      },
      News: {
        Save: '/portal/owner/news/save/:id',
        Create: '/portal/owner/news/save'
      }
    },
    Payment: {
      Index: '/portal/payment',
      Success: '/portal/payment/success'
    },
    Profile: '/portal/profile'
  },
  PlayServer: '/play-server/:id',
  Index: '/'
}
